<app-side-nav
  [sidenavTemplateRef]="navContent"
  [direction]="'left'"
  [navWidth]="280"
  [duration]="0.5">
</app-side-nav>
<ng-template #navContent>
  <!-- <app-note-list></app-note-list> -->
</ng-template>


<canvas id="my-canvas" #canvas></canvas>

<div #scrollDiv class="bg-fill bg-green">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div *ngIf="!isLoading && note" class="document">

    <div class="header">
      <div>
        <p *ngIf="saved" class="saved save-indicator">Saved</p>
        <p *ngIf="!saved" class="save-indicator">Saving...</p>
        <input type="text" [(ngModel)]="note.title" (keyup)="onUpdate()" placeholder="Add a Title">
      </div>
      <h2>{{note.date.toDate() | date: 'fullDate'}}</h2>
    </div>

    <textarea
    autosize
    [(ngModel)]="note.content"
    (keyup)="onUpdate()"
    (resized)="onResized($event)"
    #textarea
    placeholder="Begin writing..."></textarea>
  </div>
</div>


<div class="upperRight">
  <svg
    *floIfNotFullscreen
    floClickToEnterFullscreen
    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
    <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
  </svg>
  <svg
    *floIfFullscreen
    floClickToExitFullscreen
    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fullscreen-exit" viewBox="0 0 16 16">
    <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"/>
  </svg>
</div>

<div class="lowerRight">
  <p *ngIf="note" (click)="goalPrompt()" [class.goalComplete]="goalComplete" class="goal">{{note.content | wordCount}}/{{goal}}</p>
  <!-- <p *ngIf="note">Saved: {{saved}}</p> -->
</div>


<!-- Sidebar toggle -->
<!-- <div class="upperLeft">
  <svg (click)="toggleNav()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
  </svg>
</div> -->

<div class="upperLeft">
  <svg floClickToExitFullscreen routerLink="/dashboard" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
  </svg>
</div>
