<div class="bg-fill bg-green">
  <div class="outline my-50">

    <app-navbar></app-navbar>

    <div class="container">
      <div class="content">
        <div class="button-row">
          <button class="button" (click)="addNote()" floClickToEnterFullscreen>Add Note</button>
        </div>
        <app-note-list></app-note-list>
      </div>
    </div>
  </div>
</div>
