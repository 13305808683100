<div class="content">
  <h2>Your Notes:</h2>
  <app-spinner *ngIf="isLoading"></app-spinner>
  <ul *ngIf="notes && !isLoading">
    <li *ngFor="let note of notes">
      <button (click)="navigate(note)" floClickToEnterFullscreen class="note-title">
        <div *ngIf="note.title">{{note.title}}</div>
        <div [ngClass]="{'note-date': note.title}">{{note.date.toDate() | date: 'fullDate'}}</div>
      </button>
      <button (click)="onDelete(note)"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash delete-btn" viewBox="0 0 16 16">
        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
      </svg></button>
    </li>
  </ul>
  <div class="mt-25" *ngIf="notes">
    <app-paginator [length]="totalNotes" [pageSize]="notesPerPage" (page)="onChangedPage($event)"></app-paginator>
  </div>

  <p *ngIf="!notes && !isLoading">No notes yet</p>
</div>
