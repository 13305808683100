<!-- side bar main wrapper -->
<div class="side-nav-bar"
    [ngClass]="{ 'side-nav-bar-collapsed': !(showSideNav | async) }">

    <!-- overlay -->
    <div class="side-nav-bar-overlay"
    [ngStyle]="{ 'transition': 'background-color ' + duration + 's, visibility ' + duration + 's' }"
    [ngClass]="{ 'side-nav-bar-overlay-collapsed': !(showSideNav | async) }"
        (click)="onSidebarClose()"></div>

    <!-- side bar-->
    <div class="side-nav-bar-menu-container"
    [ngStyle]="getSideNavBarStyle((showSideNav | async))">

        <!-- close button -->
        <div><span class="side-nav-bar-close" (click)="onSidebarClose()"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg></span></div>

        <!-- side bar content -->
        <div class="side-nav-bar-content-container">
            <ng-container *ngTemplateOutlet="sidenavTemplateRef"></ng-container>
        </div>
    </div>
</div>
